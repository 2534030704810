// For types scoped ONLY to Onboarding

// ==== Enums ====
export enum EOnboardingAPIResponseTypes {
  VALID = "Valid",
}

export enum EOnboardingModelTypes {
  EMPLOYMENT = "employment",
  DEMOGRAPHIC = "demographic",
  INDIVIDUAL = "individual",
  ADDRESSES = "addresses",
  DOCUMENTS = "documents",
  AFFILIATIONS = "affiliations",
  CAIS = "caisCustomerType",
  // Affiliation types
  PUBLIC_COMPANY = "publicCompany",
  FIRM = "firm",
  INDUSTRY = "industry",
  ACCOUNT_OPENINGS = "accountOpenings",
  TRUSTED_CONTACTS = "trustedContacts",
  ACCOUNT_FEATURE = "accountFeature",
}

// Forms that control us all
export enum EOnboardingForms {
  ADDRESS_COLLECTION = "addressCollection",
  ACCOUNT_CREATION = "accountCreation",
  BENEFICIARIES = "beneficiaries",
  DOCUMENTS = "documents",
  EMPLOYMENT_INFORMATION = "employmentInformation",
  INDIVIDUAL = "individual",
  STOCK_EXCHANGE_ASSOCIATION = "stockExchangeAssociation",
  STONEX_AFFILIATE = "stonexAffiliate",
  CAIS = "CAISCustomerType",
  PERSONAL_INFO = "personalInfo",
  TRUSTED_CONTACT = "trustedContact",
  UPLOAD_ID = "uploadId",
}

// This is a fake enum, TS can't extend enums like you'd think - https://github.com/microsoft/TypeScript/issues/17592
//
// Not all screens in Onboarding are forms, the definitions for those go here
export const EOnboardingScreens = {
  ...EOnboardingForms,
  CHECK_EMAIL: "checkEmail",
  JOINT_ACCOUNT_HOLDER: "jointAccountHolderForm",
  SUCCESS: "success",
  SUMMARY: "summary",
  WARP_MARGIN: "warpMargin",
  WARP_OPTIONS: "warpOptions",
};

export const E_CAIS_Types = {
  Accredited: "accredited",
  Advisor: "advisor",
  Cp: "cp",
  Employee: "employee",
  Foreign: "foreign",
  NotApplicable: "notApplicable",
  OtherBroker: "otherBroker",
};

// ==== Interfaces ====
export interface IBeneficiaries {
  accountOpeningId: string;
  beneficiaryType: number;
  dateOfBirth: string;
  firstName: string;
  id?: string;
  lastName: string;
  maritalStatus?: number;
  percentage: number;
  relationship: number;
}

export interface IBeneficiaryAccount {
  accountType: number;
  beneficiaryRelationshipType: number;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  firstName: string;
  id: string;
  lastName: string;
  maritalStatus?: number;
  percentageAllocated: number;
}

export interface IAccountOpening {
  accountFeature: IAccountFeature;
  accountKey: string;
  accountNumber: string;
  active: boolean;
  beneficiaries: [IBeneficiaries];
  createDate: string;
  id: string;
  investmentObjective: number;
  modifiedDate: string;
  openType: number;
  optionsLevel: number;
  profileId: string;
  sourceSystem: string;
  status: number;
  termsAgreements: [ITermsAgreements];
}

export interface IAccountFeature {
  id?: string;
  margin?: boolean;
  noboOptOut?: boolean;
  options?: boolean;
}

export interface IOnboardingProfile {
  accountOpenings: IAccountOpening[];
  addresses: IAddress[];
  affiliations: {
    firm: IFirmAffiliation;
    id: string;
    industry: IIndustryAffiliation;
    publicCompany: IPublicCompanyAffiliation;
  };
  caisCustomerType: ICAISForm;
  demographic: IProfileDemographics;
  documents: IDocument[];
  employment: IEmployment;
  id: string;
  individual: IIndividualProfile;
  trustedContacts: ITrustedContact[];
}

// ==== API ====
export interface IIndividualProfile {
  birthDate: string;
  businessPhone?: string;
  citizenshipCountry: string;
  countryCode: string;
  email: string;
  firstName: string;
  foreignTaxId: string;
  id: string;
  lastName: string;
  middleName: string;
  mobilePhone?: string;
  phoneNumber: string;
  suffix: string;
  taxId: string;
}

export interface IAddress {
  city?: string;
  country?: string;
  id: string;
  name?: string;
  profileId: string;
  stateProvince?: string;
  street1?: string;
  street2?: string;
  type: number;
  zipPostal?: string;
}

export interface ITrustedContact {
  city?: string;
  country?: string;
  countryCode?: string;
  email?: string;
  firstName?: string;
  id: string;
  lastName?: string;
  phoneNumber?: string;
  profileId?: string;
  relation?: string;
  state?: string;
  street1?: string;
  street2?: string;
  zipCode?: string;
}

export interface ITermsAgreements {
  accountOpeningId: string;
  agreement: boolean;
  documentName: string;
  id?: string;
  masterDocumentId: string;
  subscriberType?: number;
  version: string;
}

export interface IDocument {
  file?: string;
  fileName?: string;
  governmentId?: IGovernmentId;
  id: string;
  profileId: string;
}

export interface IGovernmentId {
  country: string;
  description?: string;
  expirationDate: string;
  id: string;
  idNumber: string;
  idType: number;
  issueDate: string;
  stateProvince?: string;
}

export interface IEmployment {
  employer?: string;
  employmentStatus: number;
  id: string;
  industry?: number;
  industryDesc?: string;
  jobFunction?: number;
  jobFunctionDesc?: string;
  jobTitle?: string;
  occupation?: number;
  occupationDesc?: string;
}

export interface IIndustryAffiliation {
  associated: boolean;
  companyName: string;
  firmClearing?: boolean;
  id: string;
}

export interface IPublicCompanyAffiliation {
  associated: boolean;
  id: string;
  name: string;
  symbol: string;
}

export interface IFirmAffiliation {
  affiliatedEntity: number;
  associated: boolean;
  firstName: string;
  id: string;
  lastName: string;
  position: string;
  relationship: number;
}

export interface IFinancialProfile {
  income: string;
  liquidNetWorth: string;
  networth: string;
}

export interface ITradingExperience {
  yearsAltInvestment?: number;
  yearsAnnuities?: number;
  yearsMutualFund?: number;
  yearsOptions?: number;
  yearsStockBonds?: number;
}

export interface IDemographics {
  id: string;
  income?: string;
  incomePrimarySource?: number;
  networth?: string;
}

export interface ICAISForm {
  accredited?: boolean;
  advisor?: boolean;
  cp?: boolean;
  employee?: boolean;
  foreign?: boolean;
  id: string;
  notApplicable?: boolean;
  otherBroker?: boolean;
}

// Because our profile demographics object is a different shape than our internat store,
// we need to have two different demographics types
export interface IProfileDemographics
  extends IDemographics,
    ITradingExperience {
  liquidNetWorth: string;
}

// ==== Forms ====
export interface IOnboardingFormData
  extends IBeneficiariesForm,
    IAddressCollectionForm,
    IAccountSelectionForm,
    IDocumentsForm,
    IIndividualForm,
    IEmploymentInformationForm,
    IStockExchangeAssociationForm,
    IStonexAffiliateForm,
    ICAISForm,
    IPersonalInfoForm,
    ITrustedContactForm,
    IUploadIdForm {}

export interface IBeneficiariesForm {
  HasBeneficiaries: boolean;
  maritalStatus: number;
}

export interface IDocumentType {
  agreement?: boolean;
  documentName: string;
  documentType: string;
  effectiveDate: string;
  file: string;
  fileName: string;
  id: string;
  version: string;
}

export interface IAddressCollectionForm {
  City: string;
  Country: string;
  StateProvince?: string;
  StreetAddress1: string;
  StreetAddress2: string;
  ZipPostal?: string;
}

export interface IAccountSelectionForm {
  accountType: number;
}

export interface IDocumentsForm {
  isNoboChecked: boolean;
  isW8Checked: boolean;
  isW8ExemptChecked: boolean;
  isW9Checked: boolean;
}

export interface IIndividualForm {
  ConfirmCountryCode: string;
  ConfirmEmail: string;
  ConfirmPhone: string;
  CountryCode: string;
  DobDay: number;
  DobMonth: number;
  DobYear: number;
  Email: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Phone: string;
  Suffix: string;
}

export interface IEmploymentInformationForm {
  Employer: string;
  EmployerCity: string;
  EmployerCountry: string;
  EmployerStateProvince: string;
  EmployerStreetAddress1: string;
  EmployerStreetAddress2: string;
  EmployerZipPostal: string;
  EmploymentStatus: number;
  Industry: number;
  IndustryDesc: string;
  JobFunction: number;
  JobFunctionDesc: string;
  JobTitle: string;
  LiquidNetWorth: string;
  Occupation: number;
  OccupationDesc: string;
  SourceOfIncome: number;
}

export interface IStockExchangeAssociationForm {
  BusinessName: string;
  CUSIP: string;
  CompanyName: string;
  IsFINRA: boolean;
  Rule114: boolean;
}

export interface IStonexAffiliateForm {
  AffiliateFirstName: string;
  AffiliateLastName: string;
  AffiliatedEntity: number;
  AreYouRelated: boolean;
  AssociationRelationshipType: number;
  WhatPosition: string;
}

export interface IPersonalInfoForm {
  CountryOfCitizenship: string;
  ForeignTaxId: string;
  USTaxId: string;
}

export interface ITrustedContactForm {
  ContactCity: string;
  ContactCountry: string;
  ContactCountryCode: string;
  ContactEmail: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContactRelationship: string;
  ContactStateProvince: string;
  ContactStreetAddress1: string;
  ContactStreetAddress2: string;
  ContactZipPostal: string;
  HasTrustedContact: boolean;
  HasTrustedContactAddress: boolean;
}

export interface IUploadIdForm {
  CountryOfIssuance: string;
  Description: string;
  DocumentId: string;
  DocumentType: string;
  ExpirationDay: string;
  ExpirationMonth: string;
  ExpirationYear: string;
  File: string;
  FileName: string;
  IdNumber: string;
  IdType: number;
  IssuedDay: string;
  IssuedMonth: string;
  IssuedYear: string;
  isFileUploaded: boolean;
}
